<template>
  <div class="DeletePopin">
    <v-card flat outlined>
      <v-card-title class="center justify-center" align-items>
        <h3>Confirmer la suppression de l'utilisateur' ?</h3>
      </v-card-title>
      <v-card-text class="card_text">
        <p>
          Êtes-vous sûr de vouloir supprimer l'utilisateur {{ user.firstname }}
          {{ user.lastname }} de cette campagne?
        </p>
        <p>
          L'utilisateur ne pourra plus accéder aux informations de cette
          campagne.
        </p>
      </v-card-text>
      <v-card-actions>
        <LowButtonSlot @click="closePopin"> Annuler </LowButtonSlot>
        <v-spacer></v-spacer>
        <HighButtonSlot @click="confirmUserDeletion"> Valider </HighButtonSlot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["user"],
  methods: {
    closePopin() {
      this.$emit("closePopin");
    },
    async confirmUserDeletion() {
      await this.$api.campaigns.removeMember(
        this.$route.params.id,
        this.user.id
      );
      this.$emit("userDeleted");
      this.closePopin();
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.DeletePopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}
</style>
