<template>
  <div class="EditPopin">
    <v-card flat outlined>
      <v-card-title class="center justify-center" align-items>
        <h3>Ajouter un membre</h3>
      </v-card-title>
      <div class="form">
        <v-row>
          <v-col cols="12" md="8">
            <v-autocomplete
              label="Organisation du client*"
              v-if="showSelectOrganization"
              :items="organizations"
              item-text="name"
              item-value="id"
              clearable
              v-model="propsOrganization.id"
              @change="getOrganizationUsers"
              :rules="[$rules.required]"
            >
            </v-autocomplete>
            <v-autocomplete
              label="Utilisateur*"
              :rules="[$rules.required]"
              outlined
              clearable
              :items="users"
              :item-text="(item) => getItemText(item)"
              item-value="id"
              v-model="newUserCampaign.userId"
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="4"
            v-if="$hasRight('users.create')"
            @click="isUserCreateModaleOpened = true"
          >
            <HighButtonSlot>Créer utilisateur</HighButtonSlot>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" v-if="isInternal()">
            <v-checkbox
              label="Membre de l'équipe*"
              v-model="newUserCampaign.isTeamMember"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox
              label="Lecture seule"
              v-model="newUserCampaign.readOnly"
            />
          </v-col>
        </v-row>
      </div>
      <v-card-actions>
        <LowButtonSlot @click="closePopin"> Annuler </LowButtonSlot>
        <v-spacer></v-spacer>
        <HighButtonSlot @click="confirmModifications()">
          Valider
        </HighButtonSlot>
      </v-card-actions>
    </v-card>
    <CreateEditUserModal
      v-if="isUserCreateModaleOpened"
      :organization="organization"
      @closePopin="isUserCreateModaleOpened = false"
      @userCreated="handleUserCreation"
      :isUserEdit="false"
    />
  </div>
</template>

<script>
import CreateEditUserModal from "../user/CreateEditUser.modal.vue";
export default {
  components: {
    CreateEditUserModal,
  },
  props: ["organization", "campaign", "members"],
  data() {
    return {
      propsOrganization: this.organization ?? { id: undefined },
      showSelectOrganization: false,
      organizations: [],
      isUserCreateModaleOpened: false,
      newUserCampaign: {
        userId: "",
        campaignId: this.$route.params.id,
        readOnly: false,
        isTeamMember: false,
      },
      users: [],
    };
  },
  computed: {
    noOrganization() {
      return this.propsOrganization?.id === undefined;
    },
  },
  methods: {
    getItemText(item) {
      const base = `${item.firstname} ${item.lastname} (${item.email}) - `;
      if (item.isInternal) return base + "interne";
      else if (item.roles.includes("ROLE_CUSTOMER_MAIN"))
        return base + "client principal";
      else return base + "client";
    },
    async handleUserCreation(newUser) {
      await this.getOrganizationUsers();
      this.newUserCampaign.userId = newUser.id;
    },
    isValidForm() {
      return this.newUserCampaign.userId !== "";
    },
    isInternal() {
      return !this.$isRoleAbove("ROLE_NEXTROAD_USER");
    },
    async getExternalOrganizations() {
      this.organizations = await this.$api.organizations.findAll(false);
    },
    async getOrganizationUsers() {
      let users = [];

      if (this.noOrganization) {
        this.showSelectOrganization = true;
      } else {
        users = this.$api.users.findByOrganization(this.propsOrganization.id);
      }

      if (this.isInternal()) {
        const internalUsers = await this.$api.users.findByOrganization(
          this.campaign.workspace.site.organization.id
        );
        users = [...users, ...internalUsers];
      }

      this.users = users.filter((user) => {
        return this.members.findIndex((member) => member.id === user.id) === -1;
      });
    },
    closePopin() {
      this.$emit("closePopin");
    },
    async confirmModifications() {
      if (!this.isValidForm()) return console.error("invalid form");
      await this.$api.campaigns.addMember(
        this.campaign.id,
        this.newUserCampaign.userId,
        this.newUserCampaign
      );
      this.$emit("userAdded");
      this.closePopin();
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
    this.getOrganizationUsers();
    this.getExternalOrganizations();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.EditPopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
}
</style>
