<template>
  <div class="notFoundContainer">
    <v-img
      src="../assets/Erreur-404.gif"
      max-height="250"
      max-width="510"
      contain
    ></v-img>
    <h1>La page n'a pas pu être trouvée</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.notFoundContainer {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
